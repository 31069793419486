<template>
  <keep-alive :include="cacheList">
    <router-view></router-view>
  </keep-alive>
</template>

<script>
export default {
  data(){
    return{
      cacheList: ['orderList']
    }
  },
}
</script>

<style>

</style>

